.checkboxBase {
	position: relative;

	.nativeInput {
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
		pointer-events: none;
	}
}
