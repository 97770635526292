.loadButton {
	position: relative;

	&.loaded [data-meta-content] {
		pointer-events: none;
		opacity: 0;
	}

	.spinner {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		position: absolute;
		width: calc(var(--button-height) * 0.7);
		height: calc(var(--button-height) * 0.7);

		svg {
			width: 100%;
			height: 100%;

			circle {
				stroke: var(--button-color);
			}
		}
	}
}
