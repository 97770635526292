.fieldBase {
	width: 100%;
	position: relative;
	display: inline-block;

	--padding-top: 0rem;
	--padding-bottom: 1rem;
	--label-font-size: 1.8rem;
	--border-color: #d8dce3;

	&:focus-within :global(.field) {
		border-color: var(--color-primary-red);
	}

	// [data-label] {
	// 	transform: translate3d(
	// 		0,
	// 		calc(var(--padding-top) * -0.35 - var(--label-font-size) / 2),
	// 		0
	// 	) !important;
	// }

	:global(.field):not(:read-only):focus-within ~ .label,
	&.constantlyOnTop .label,
	:global(.field)[value]:not([value=""]) ~ .label {
		top: 0;
		font-size: 1.2rem;
		transform: translate3d(0, calc(-2rem), 0);
	}

	.label {
		left: 0;
		top: 0;
		font-size: var(--label-font-size);
		font-weight: 700;
		overflow: hidden;
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: var(--color-text-light);
		pointer-events: none;
		transform: translate3d(0, 0, 0);
		transition: top 0.4s ease, font-size 0.4s ease, transform 0.4s ease;
		// z-index: 1;
	}

	:global(.field) {
		width: 100%;
		font-weight: 600;
		font-size: 1.8rem;
		line-height: 2.4rem;
		appearance: none;
		border-bottom: 1px solid var(--border-color);
		padding: var(--padding-top) 0 var(--padding-bottom);

		&::placeholder {
			color: var(--color-lines);
		}

		&:disabled,
		&[readonly] {
			background: #f7f7f7;
		}
	}

	.label {
		position: absolute;
	}
}
