.align {
	display: flex;
	flex-grow: 1;
	justify-content: flex-start;
	align-items: flex-start;

	&.x {
		justify-content: center;
	}

	&.y {
		align-items: center;
	}

	&.adaptable {
		&:not(.x) {
			width: 100%;
		}

		&:not(.y) {
			height: 100%;
		}

		&.x {
			min-width: 100%;
		}

		&.y {
			min-height: 100%;
		}
	}

	&:not(.adaptable) {
		width: 100%;
		height: 100%;
		max-height: 100%;
		max-width: 100%;
	}
}
