.prompt {
	width: 2.5rem;
	height: 2.5rem;
	font-size: 1.4rem;
	color: #7c8da6;
	background: #f5f7fb;
	border-radius: 50%;
	line-height: 2.5rem;
	text-align: center;
}
