@font-face {
	font-family: ProximaNova;
	font-style: normal;
	font-weight: 800;
	src: url(../assets/fonts/ProximaNova/ProximaNova-Extrabld.woff) format("woff"),
		url(../assets/fonts/ProximaNova/ProximaNova-Extrabld.ttf) format("truetype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Extrabld.eot) format("embedded-opentype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Extrabld.svg#ProximaNova-Extrabld.eot) format("svg");
}
@font-face {
	font-family: ProximaNova;
	font-style: normal;
	font-weight: 700;
	src: url(../assets/fonts/ProximaNova/ProximaNova-Bold.woff) format("woff"),
		url(../assets/fonts/ProximaNova/ProximaNova-Bold.ttf) format("truetype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Bold.eot) format("embedded-opentype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Bold.svg#ProximaNova-Bold.eot) format("svg");
}
@font-face {
	font-family: ProximaNova;
	font-style: normal;
	font-weight: 600;
	src: url(../assets/fonts/ProximaNova/ProximaNova-Semibld.woff) format("woff"),
		url(../assets/fonts/ProximaNova/ProximaNova-Semibld.ttf) format("truetype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Semibld.eot) format("embedded-opentype");
}
@font-face {
	font-family: ProximaNova;
	font-style: normal;
	font-weight: 400;
	src: url(../assets/fonts/ProximaNova/ProximaNova-Reg.woff) format("woff"),
		url(../assets/fonts/ProximaNova/ProximaNova-Reg.ttf) format("truetype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Reg.eot) format("embedded-opentype"),
		url(../assets/fonts/ProximaNova/ProximaNova-Reg.svg#ProximaNova-Reg) format("svg");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 900;
	src: url(../assets/fonts/Bereg/BeregSans-Black.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 900;
	src: url(../assets/fonts/Bereg/BeregSans-BlackItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 900;
	src: url(../assets/fonts/Bereg/BeregSans-Heavy.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 900;
	src: url(../assets/fonts/Bereg/BeregSans-HeavyItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 800;
	src: url(../assets/fonts/Bereg/BeregSans-ExtraBold.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 800;
	src: url(../assets/fonts/Bereg/BeregSans-ExtraBoldItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 700;
	src: url(../assets/fonts/Bereg/BeregSans-Bold.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 700;
	src: url(../assets/fonts/Bereg/BeregSans-BoldItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 600;
	src: url(../assets/fonts/Bereg/BeregSans-SemiBold.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 600;
	src: url(../assets/fonts/Bereg/BeregSans-SemiBoldItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 400;
	src: url(../assets/fonts/Bereg/BeregSans-Regular.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 400;
	src: url(../assets/fonts/Bereg/BeregSans-RegularItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 300;
	src: url(../assets/fonts/Bereg/BeregSans-Light.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 300;
	src: url(../assets/fonts/Bereg/BeregSans-LightItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 200;
	src: url(../assets/fonts/Bereg/BeregSans-ExtraLight.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 200;
	src: url(../assets/fonts/Bereg/BeregSans-ExtraLightItalic.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: normal;
	font-weight: 100;
	src: url(../assets/fonts/Bereg/BeregSans-Thin.otf) format("opentype");
}
@font-face {
	font-family: Bereg;
	font-style: italic;
	font-weight: 100;
	src: url(../assets/fonts/Bereg/BeregSans-ThinItalic.otf) format("opentype");
}
