@import "../../../styles/fragments/grid.mixins";

.button {
	cursor: pointer;
	font-weight: 600;
	text-align: left;
	max-width: 100%;
	border-radius: 1rem;
	padding: 0 3.2rem;
	font-size: 1.8rem;
	font-family: var(--font-secondary);
	background: var(--button-background);
	color: var(--button-color);
	height: var(--button-height);

	--button-height: 5.5rem;

	// Themes
	&.primary {
		--button-color: white;
		--button-background: var(--color-primary);
		--button-counter-color: var(--color-primary);
	}

	&.primary-red {
		--button-color: white;
		--button-background: var(--color-primary-red);
		--button-counter-color: var(--color-primary-red);
	}

	&.secondary,
	&:disabled {
		--button-color: #566173;
		--button-background: #e6ebf2;
		--button-counter-color: var(--color-text-light);
	}

	&.tertiary {
		--button-color: #191c75;
		--button-background: rgba(255, 255, 255, 0);
		--button-counter-color: white;
	}

	&.outline {
		--button-color: var(--color-primary);
		--button-background: rgba(255, 255, 255, 0);
		--button-counter-color: white;
		border: 1px solid var(--color-primary);
	}

	&.extra {
		--button-color: white;
		--button-background: #9428fa;
		--button-counter-color: #9428fa;
	}

	&.overlay-primary {
		--button-color: #333;
		--button-background: white;
		--button-counter-color: white;
	}

	&.overlay-secondary {
		--button-color: white;
		--button-background: rgba(255, 255, 255, 0.25);
		--button-counter-color: #333;
	}

	&.overlay-outline {
		--button-color: white;
		--button-background: rgba(255, 255, 255, 0);
		--button-counter-color: #333;
		border: 1px solid white;
	}

	&.fixedLayout {
		width: 26rem;
	}

	&.flexible {
		@include media-breakpoint-down("md") {
			height: auto;
			text-align: left;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		height: 100%;
	}

	.metaGroup {
		display: flex;
		flex-direction: row;
		align-items: center;

		&.left {
			margin-right: 0.8rem;

			span:nth-child(2) {
				margin-right: inherit;
			}
		}

		&.right {
			margin-left: 0.8rem;

			span:nth-child(2) {
				margin-left: inherit;
			}
		}

		.iconGroup {
			fill: var(--button-color);
			max-width: 2.4rem;
			height: 2.4rem;
		}

		.counter {
			width: 2.4rem;
			height: 2.4rem;
			line-height: 2.4rem;
			text-align: center;
			font-size: inherit;
			border-radius: 50%;
			color: var(--button-counter-color);
			background: var(--button-color);
		}
	}
}
