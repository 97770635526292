@import "../../styles/fragments/grid.mixins";

.banner {
	.imageGroup {
		position: absolute;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;

		.image {
			&.mobile {
				display: none;

				&:before {
					content: "";
					top: 0;
					left: 0;
					display: block;
					position: absolute;
					width: 100%;
					height: 90%;
					background: linear-gradient(180deg, white, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
				}
			}

			@include media-breakpoint-down("md") {
				&:not(.mobile) {
					display: none;
				}

				&.mobile {
					display: block;
				}
			}
		}

		img {
			object-fit: cover;
		}
	}
}
