@import "../../../styles/fragments/grid.mixins";

.container {
	@include media-breakpoint-up("xl") {
		max-width: 1140px !important;
	}

	@include media-breakpoint-down("md") {
		--bs-gutter-x: 2rem !important;
	}
}
