@import "../../styles/fragments/grid.mixins";

.banner {
	.imageGroup {
		position: absolute;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;

		.image {
			&.mobile {
				display: none;
			}

			@include media-breakpoint-down("md") {
				&:not(.mobile) {
					display: none;
				}

				&.mobile {
					display: block;
				}
			}
		}

		img {
			object-fit: cover;
		}
	}
}
