@import "../../styles/fragments/grid.mixins";

.section {
	.wrapper {
		box-shadow: none;
		background: var(--color-primary) !important;
		max-width: initial;
		width: 100vw;
		border-radius: 0;
		padding: 0;

		.content {
			.title {
				color: white;

				span {
					display: inline-block;
				}
			}

			.carousel {
				padding: 0;
				height: 36rem;
				overflow: hidden;
				margin-top: 4rem;
				position: relative;
				border-radius: 3rem 0 0 3rem;

				.sliderWrapper {
					margin: 0 -1rem;
					height: 100%;

					.sliderGroup {
						width: 100%;
						height: 100%;

						.slider {
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							height: 100%;
							transform: translateX(
								calc(var(--slide-index) * -1 * (var(--container-width) * var(--slide-width-factor)))
							);
							transition: transform 0.3s ease;

							.slideGroup {
								flex: 0 0 auto;
								width: calc(var(--container-width) * var(--slide-width-factor));
								height: 100%;
								padding: 0 1rem;

								&.extra {
									width: calc(var(--container-width) - var(--container-width) * var(--slide-width-factor));

									.slide {
										display: flex;
										flex-direction: column;
										align-items: center;
										justify-content: center;
										background: rgba(255, 255, 255, 0);
										height: inherit;
										border: 1px solid white;
										transition: background 0.3s ease;

										&:hover {
											background: rgba(255, 255, 255, 0.1);
										}

										.content {
											color: white;
											text-align: center;
											font-weight: 600;
											font-size: 1.8rem;
										}
									}

									@include media-breakpoint-down("md") {
										width: calc(var(--container-width) * var(--slide-width-factor));
									}
								}

								.slide {
									width: 100%;
									height: inherit;
									background: white;
									overflow: hidden;
									border-radius: 3rem;
								}
							}

							@include media-breakpoint-down("md") {
								height: 100%;
								width: 100%;
								transform: none;
								padding: 0 1.5rem;
								overflow-y: auto;
							}
						}
					}

					--slide-width-factor: 0.75;

					@include media-breakpoint-down("md") {
						--slide-width-factor: 0.8;
					}
				}

				.controllers {
					position: absolute;
					padding-top: 0;
					bottom: 4rem;
					left: 0;

					.buttonGroup {
						display: flex;
						flex-direction: row;

						.button {
							width: 4.6rem;
							height: 4.6rem;
							border-radius: 50%;
							background: var(--color-primary);
							position: relative;
							margin-top: 2rem;

							&:not(:nth-child(1)) {
								margin-left: 1.5rem;
							}

							&.next .icon {
								transform: rotate(-90deg);
							}

							&.prev .icon {
								transform: rotate(90deg);
							}

							.icon {
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								margin: auto;
								position: absolute;
								width: 2rem;
								fill: white;
							}
						}
					}

					@include media-breakpoint-down("md") {
						display: none;
					}
				}

				@include media-breakpoint-down("md") {
					height: 80vh;
					border-radius: 0;
				}
			}
		}
	}

	@include media-breakpoint-up("md") {
		margin-top: 5rem !important;
	}
}
