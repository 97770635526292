@import "../../../styles/fragments/grid.mixins";

.title {
	font-weight: 800;
	font-size: 3rem;
	line-height: 4.4rem;
	font-family: var(--font-primary);

	@include media-breakpoint-down("md") {
		text-align: center;
	}
}
