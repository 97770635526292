@import "../../../styles/fragments/grid.mixins";

.modal {
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	position: fixed;
	background: var(--color-background-modal);
	backdrop-filter: blur(2px);
	overflow: hidden;
	overflow-y: auto;
	z-index: 999;
	display: none;
	opacity: 0;
	visibility: hidden;

	.content {
		width: 55rem;
		max-height: calc(100vh - 10rem);
		padding: 3.5rem 2.5rem;
		overflow-y: auto;
		position: relative;
		background: var(--color-background-secondary);
		box-shadow: 0 34px 134px rgba(255, 255, 255, 0.35), 0 20px 100px rgba(0, 0, 0, 0.08),
			inset 154px 135px 187px var(--color-background-secondary);
		border-radius: 2.4rem;

		.close {
			font-weight: 400;
			font-size: 4rem;
			line-height: 2.2rem;
			position: relative;
			color: var(--color-text-light);
			opacity: 0.5;
			cursor: pointer;
			position: absolute;
			right: 2rem;
			top: 2rem;

			&:hover {
				color: var(--color-text-primary);
				opacity: 1;
			}
		}

		button {
			font-size: 1.6rem;
		}

		@include media-breakpoint-down("md") {
			width: 100% !important;
			height: 100vh !important;
			max-height: initial;
			overflow-y: auto;
			border-radius: 0;
		}
	}
}
