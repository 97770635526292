@import "../../../styles/fragments/grid.mixins";

.datepicker {
	position: absolute !important;
	z-index: 9999;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	top: 0;

	--polygon-height: 1rem;
	--polygon-width: 1.5rem;

	&:after,
	&:before {
		content: "";
		width: 0;
		height: 0;
		left: 2rem;
		position: absolute;
	}

	&:after {
		transform: translate(1px, 1px);
		border-left: calc(var(--polygon-height) - 1px) solid transparent;
		border-right: calc(var(--polygon-height) - 1px) solid transparent;
		border-bottom: calc(var(--polygon-height) - 1px) solid var(--color-background-secondary);
	}

	&:before {
		border-left: var(--polygon-height) solid transparent;
		border-right: var(--polygon-height) solid transparent;
		border-bottom: var(--polygon-height) solid #f5f5f5;
	}

	&.visible {
		pointer-events: all;
		visibility: visible;
		opacity: 1;
	}

	&.top {
		padding-bottom: var(--polygon-height);

		&:after,
		&:before {
			bottom: 1px;
		}

		&:after {
			transform: translate(1px, -1px) rotate(180deg);
		}

		&:before {
			transform: rotate(180deg);
		}
	}

	&.bottom {
		padding-top: var(--polygon-height);

		&:after,
		&:before {
			top: 1px;
		}
	}

	.content {
		padding: 1.5rem;
		width: 32rem;

		.head {
			font-weight: 700;
		}

		.controls {
			display: grid;
			grid-template-columns: auto 1fr auto;

			.item.title {
				width: 100%;
			}
		}

		.row {
			display: flex;
			flex-direction: row;

			&.dates {
				display: grid;
				grid-template-columns: repeat(var(--columns-count), 1fr);
			}
		}

		button.item:not(:disabled):not(.selected):hover {
			background: #eee;
		}

		button.item:disabled {
			color: var(--color-text-light);
			cursor: not-allowed;
		}

		.item {
			width: 100%;
			min-width: 4rem;
			height: 3.5rem;
			line-height: 3.5rem;
			text-align: center;
			border-radius: 0.8rem;

			&.current {
				font-weight: 600;
				color: var(--color-primary);
			}

			&.outside {
				color: var(--color-text-light);
			}

			&.selected {
				color: white;
				font-weight: inherit;
				background: var(--color-primary);
			}

			&.month,
			&.year {
				height: 5rem;
				line-height: 5rem;
			}
		}
	}

	@include media-breakpoint-down("md") {
		display: none !important;
	}
}
