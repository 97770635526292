.modal {
	text-align: center;

	.titleGroup {
		margin: 4rem 0;

		.title {
			font-family: var(--font-primary);
			font-weight: 800;
			font-size: 3.2rem;
			line-height: 3.6rem;
		}

		.subtitle {
			font-weight: 600;
			// font-size: 1.6rem;
			text-align: center;
			margin-top: 1.5rem;
			font-size: 1.6rem;
			color: var(--color-text-light);
		}
	}
}
