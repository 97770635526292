.modal {
	text-align: center;

	.content {
		margin: 0 2rem;

		.titleGroup {
			// margin: 0 2rem;

			.subtitle {
				font-weight: 600;
				// font-size: 1.6rem;
				text-align: center;
				margin-top: 1.5rem;
				font-size: 1.6rem;
				color: var(--color-text-light);
			}
		}

		.form {
			margin: 4rem 0;

			.field {
				text-align: left;

				&:not(:nth-child(1)) {
					margin-top: 4rem;
				}
			}
		}

		.button {
			width: 100%;
		}
	}
}
