@import "../../styles/fragments/grid.mixins";

.banner {
	.title {
		.highlighted {
			color: var(--color-primary);
		}
	}

	.background {
		width: 100%;
		height: 100%;
		position: relative;

		.imageGroup {
			position: absolute;
			right: -5rem;
			bottom: -1rem;
			width: 45rem;
			height: 33rem;

			img {
				object-fit: contain;
			}

			@include media-breakpoint-down("md") {
				width: 140%;
				bottom: -1rem;
				right: -20%;
				height: auto;
				max-width: none;

				img {
					height: auto;
				}
			}
		}
	}
}
