@import "../../styles/fragments/grid.mixins";

.section {
	background: var(--color-primary-gradient);

	.contacts {
		display: flex;
		flex-direction: row;
		align-items: center;

		&.mobile {
			display: none;
		}

		.phoneNumber {
			font-weight: 600;
			font-size: 1.8rem;
			color: white;

			&:hover {
				text-decoration: underline;
			}

			@include media-breakpoint-down("lg") {
				margin-left: 1rem;
			}
		}

		.messengers {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 2rem;

			> :not(:nth-child(1)) {
				margin-left: 1rem;
			}

			.icon {
				width: 2.6rem;
				height: 2.6rem;
				fill: white;
			}

			@include media-breakpoint-down("lg") {
				margin-left: 0;
			}
		}

		@include media-breakpoint-down("lg") {
			justify-content: center;
			flex-direction: row-reverse;
			width: 100%;

			&.mobile {
				display: flex;
			}

			&:not(.mobile) {
				display: none;
			}
		}
	}

	.wrapper {
		padding-top: 4rem;
		padding-bottom: 4rem;
		// height: 100%;

		.container {
			.formColumn {
				padding-right: 0;

				@include media-breakpoint-down("lg") {
					padding: 0 1rem;
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-top: 5rem;
				height: 60rem;

				.logoGroup {
					display: flex;
					flex-direction: row;
					align-items: center;
					height: 3.5rem;

					.logo {
						width: auto;
						height: 3.5rem;
						text-align: left;
					}

					.separator {
						flex: 0 0 1px;
						width: 1px;
						height: 100%;
						background: white;
						margin: 0 2.5rem;

						@include media-breakpoint-down("lg") {
							margin: 0 2rem;
						}
					}

					@include media-breakpoint-down("lg") {
						justify-content: center;
						margin-top: 0;
						width: 100%;
					}
				}

				.description {
					color: white;
					font-family: var(--font-primary);
					font-weight: 400;
					font-size: 4.8rem;
					line-height: 6rem;

					p.mobile {
						display: none;
					}

					span {
						font-weight: 800;
					}

					.note {
						font-size: 0.6em;
						line-height: 125%;
						margin-top: 1.5rem;
					}

					@include media-breakpoint-down("lg") {
						font-size: 2.2rem;
						line-height: 2.6rem;
						text-align: center;
						margin: 3.6rem 0 3rem;

						> p.mobile {
							display: initial;
						}

						> p:not(.mobile) {
							display: none;
						}

						.note {
							font-size: 0.8em;
							line-height: 125%;
							margin-top: 0.5rem;
						}
					}
				}

				@include media-breakpoint-down("lg") {
					padding-top: 0;
					display: block;
					height: auto;
				}
			}

			.form {
				padding: 5rem 10rem;
				margin-right: calc(var(--container-wrapper-padding) * -1 - var(--bs-gutter-x) * 0.5);

				.title {
					font-size: 3.2rem;

					@include media-breakpoint-down("lg") {
						font-size: 2.2rem;
						line-height: 2.6rem;
					}
				}

				.formContent {
					margin: 7rem 0 9rem;

					@include media-breakpoint-up("lg") {
						> :global(.row) {
							> div:nth-child(1) .field {
								margin-right: 2rem !important;
							}

							> div:nth-child(2) .field {
								margin-left: 2rem !important;
							}
						}
					}

					.fieldGroup {
						min-height: 3.5rem;

						// margin: 0 -10rem;

						// .field {
						// 	position: relative;

						// 	@include media-breakpoint-up("lg") {
						// 		~ [data-error-message] {
						// 			position: absolute;
						// 			text-align: left;
						// 			top: 100%;
						// 		}
						// 	}
						// }

						&:not(:nth-child(1)) {
							margin-top: 4rem;
						}

						@include media-breakpoint-down("lg") {
							min-height: none;
						}
					}

					@include media-breakpoint-down("lg") {
						margin: 4rem 0;

						> :global(.row) > div:not(:nth-child(1)) {
							margin-top: 4rem;
						}
					}
				}

				.controls {
					.buttonGroup {
						display: flex;
						flex-direction: column;
					}

					.priceGroup {
						text-align: left;

						.price {
							&.old {
								font-size: 1.8rem;
								line-height: 2.4rem;
								color: rgba(124, 141, 166, 0.75);
								text-decoration: line-through;
							}

							&.current {
								font-family: var(--font-primary);
								font-weight: 800;
								font-size: 2.8rem;
								line-height: 4.4rem;
								color: var(--color-primary-red);
							}
						}

						@include media-breakpoint-down("lg") {
							text-align: center;
						}
					}

					.button {
						text-align: center;

						&.main {
							width: 100%;
							display: block;
							margin: 0 auto;
							overflow: hidden;
							position: relative;

							&:not(:disabled):after {
								content: "";
								height: 200%;
								top: -50%;
								position: absolute;
								width: 3rem;
								left: -5rem;
								background: linear-gradient(
									to right,
									rgba(255, 255, 255, 0) 0%,
									rgba(255, 255, 255, 0.9) 50%,
									rgba(255, 255, 255, 0) 100%
								);
								opacity: 0.25;
								transform: rotate(30deg);
								animation: movingFlare 2s ease-in-out infinite;
							}

							@keyframes movingFlare {
								0% {
									left: -5rem;
									//   margin-left: 0px;
								}
								60% {
									left: 110%;
									//   margin-left: 80px;
								}
								100% {
									left: 110%;
									//   margin-left: 80px;
								}
							}
						}

						&.manager {
							margin-top: 2rem;
						}
					}
				}

				@include media-breakpoint-down("lg") {
					padding: 2.5rem 2rem;
					border-radius: 2rem;
					margin-right: 0;
					width: calc(100% + 2rem);
					margin-left: -1rem;
					margin-bottom: 3rem;
				}
			}
		}
	}
}
