@import "fonts.scss";
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "./fragments/grid.mixins";

iframe {
	pointer-events: none;
}

html {
	font-size: 10px;
	-webkit-tap-highlight-color: transparent;

	--border-radius-card: 2rem;
	--border-radius-square: 1.2rem;
	--border-radius-circle: 50%;

	--font-primary: "Bereg", Arial, sans-serif;
	--font-secondary: "ProximaNova", Arial, sans-serif;
}

body {
	overflow: hidden;
	overflow-y: auto;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant-ligatures: normal;
	font-variant-caps: normal;
	font-variant-numeric: normal;
	font-variant-east-asian: normal;
	font-weight: 400;
	font-size: 1.4rem;
	font-stretch: normal;
	font-family: var(--font-secondary);
	background: var(--color-background-primary);

	&.light-theme {
		--color-background-primary: #f7f7f7;
		--color-background-secondary: #fff;
		--color-text-primary: #141736;
		--color-text-light: #7c8da6;
		--color-primary: #3e30ff;
		--color-secondary: #9a5aff;
		--color-grey: #969696;
		--color-border: #f0f0f0;
		--color-border-light: #e4eaf3;
		--color-red: #f00;
		--color-grey: #9c9c9c;
		--color-pink: #ff6370;
		--color-orange: #f5a733;
		--color-background-modal: rgba(0, 0, 0, 0.5);
		--color-button-text: #fff;
		--color-lines: #bdcce3;
		--color-lines-background: rgba(189, 204, 227, 0.1);
		--color-primary-red: #d0233a;
		--color-primary-gradient: linear-gradient(
				238.45deg,
				#ffffff -135.08%,
				rgba(255, 255, 255, 0) 30.08%
			),
			#d02138;

		--box-shadow-card-color: rgba(0, 0, 0, 0.05);
		--box-shadow-card: 0px 1.5rem 10.5rem var(--box-shadow-card-color);

		--box-shadow-button: 0 0.9rem 2.4rem rgba(47, 95, 248, 0.2),
			inset 0 0.7rem 1.2rem rgba(255, 255, 255, 0.1),
			inset 0 -0.5rem 1.5rem -0.2rem rgba(255, 255, 255, 0.45);
	}

	&.grabbed {
		* {
			cursor: grabbing !important;
		}
	}
}

footer {
	margin-top: 10rem;
}

.card {
	background: white;
	border-radius: 3rem;
	box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.04);

	@include media-breakpoint-down("md") {
		border-radius: 0;
	}
}

.banner {
	padding: 6rem 10rem 0;

	@include media-breakpoint-down("md") {
		padding: 6rem 2rem 0;
	}
}

.link {
	color: var(--color-primary);
	font-weight: 600;
	text-decoration: underline;

	&.disabled {
		color: var(--color-text-light);
	}

	&:not(.disabled):hover {
		text-decoration: none;
	}
}

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
}

* {
	margin: 0;
	padding: 0;
	color: inherit;
	background: initial;
	box-sizing: border-box;
	border: initial;
	font-family: inherit;
	font-weight: inherit;
	cursor: inherit;
	text-align: inherit;
	font-size: inherit;
	text-decoration: initial;
	word-wrap: break-word;
	text-transform: inherit;

	&:not(:focus) {
		outline: none;
	}
}

input:focus {
	outline: none;
}

img {
	max-width: 100%;
	max-height: 100%;
}

input,
textarea,
select {
	border: initial;
	padding: initial;
	background: initial;
}

button {
	cursor: pointer;
}

svg {
	width: 100%;
	height: 100%;

	&:not(.original) {
		fill: inherit;
		stroke: inherit;
		stroke-width: inherit;

		g,
		path {
			stroke: inherit;
			fill: inherit;
			stroke-width: inherit;
		}
	}
}

a {
	cursor: pointer;
}

main section:not(:nth-child(1)) {
	margin-top: 3rem;
}

.noselect {
	user-select: none;
}

.container {
	--bs-gutter-x: 1.5rem;
}

.hiddenContent {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	pointer-events: none;
	opacity: 0;
	overflow: hidden;
}
