@import "../../styles/fragments/grid.mixins";

.section {
	.content {
		.questions {
			margin-top: 4rem;

			.question {
				cursor: pointer;
				border-radius: 2rem;
				transition: box-shadow 0.3s ease;

				&:hover {
					box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
				}

				&:not(:nth-child(1)) {
					margin-top: 1.5rem;
				}

				.head {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 2.5rem 0;

					--icon-size: 2.4rem;
					--icon-offset: 1rem;

					.title {
						flex: 0 0 calc(100% - var(--icon-size) - var(--icon-offset));
						max-width: calc(100% - var(--icon-size) - var(--icon-offset));
						font-family: var(--font-secondary);
						font-weight: 600;
						font-size: 2rem;
						line-height: 2.4rem;
						text-align: left !important;
					}

					.icon {
						margin-left: var(--icon-offset);
						width: var(--icon-size);
						height: var(--icon-size);
						fill: #7c8da6;
					}
				}

				.body {
					border-top: 1px solid var(--color-border);
					padding: 2.5rem 0;
					display: none;
					cursor: auto;

					.answer {
						font-weight: 500;
						font-size: 1.8rem;
						line-height: 2.4rem;

						ul {
							padding: 1.5rem 0 1.5rem 2rem;
						}

						a {
							color: var(--color-primary);
							text-decoration: underline;

							&:hover {
								text-decoration: none;
							}
						}

						b {
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}
