@import "../../../styles/fragments/grid.mixins";

.reasons {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;

	.reason {
		.title {
			font-family: var(--font-primary);
			font-weight: 800;
			font-size: 4.8rem;
			line-height: 6rem;
			color: var(--color-primary-red);

			@include media-breakpoint-down("md") {
				font-size: 3.8rem;
			}
		}

		.description {
			font-weight: 600;
			font-size: 2.4rem;
			line-height: 3rem;
			margin-top: 0.5rem;

			@include media-breakpoint-down("md") {
				font-size: 2.2rem;
				margin-top: 0;
			}
		}

		@include media-breakpoint-down("md") {
			&:not(:nth-child(1)) {
				margin-top: 2.4rem;
			}
		}
	}

	@include media-breakpoint-down("md") {
		flex-direction: column;
	}
}
