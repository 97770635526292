.errorField {
	position: relative;
	z-index: 1;

	.field {
		position: relative;
		// z-index: 1;

		&.error {
			animation: shake 1s ease both;

			--border-color: #f91155;
		}
	}

	.message {
		text-align: left;
		font-weight: 500;
		font-size: 1.2rem;
		padding-top: 1rem;
		color: #f91155;
		width: 100%;
		z-index: -1;

		&.prompt {
			color: var(--color-text-light);
		}
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
