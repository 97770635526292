@import "../../../styles/fragments/grid.mixins";

.select {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	position: absolute;
	cursor: pointer;
	z-index: 1;

	&:global(.opened) {
		z-index: 9;

		.head .iconGroup {
			transform: rotate(180deg);
		}

		.optionGroup {
			display: block;
		}
	}

	.head {
		width: 100%;
		display: flex;
		position: relative;

		--icon-size: 2rem;

		.label {
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 2rem;
			line-height: 2.4rem;
			padding-right: calc(var(--icon-size) + 1rem);
		}

		.iconGroup {
			top: calc(1.2rem - var(--icon-size) / 2);
			position: absolute;
			right: 0;
			line-height: 0;
			width: var(--icon-size);
			fill: #7c8da6;
		}
	}

	.optionGroup {
		padding: 0.3rem 0 !important;
		max-height: 15rem;
		overflow-y: auto;
		background: var(--color-background-secondary);
		border-radius: var(--border-radius-square);
		box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
		border: 1px solid rgba(216, 220, 227, 0.5) !important;
		display: none;
		margin-top: 1rem;

		.option {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			position: relative;
			padding: 1rem 1.5rem;
			color: var(--color-text-light);
			background: transparent;
			transition: color 0.2s linear, background 0.2s linear;

			&.selected,
			&:not(.selected):hover {
				color: var(--color-text-primary);
			}

			&:not(.selected):hover {
				background: var(--color-lines-background);
			}

			&.selected {
				.label {
					padding-right: 1rem;
				}

				.iconGroup {
					display: block;
				}
			}

			.label {
				max-width: 100%;
				font-size: 2rem;
				line-height: 2.4rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.iconGroup {
				width: 1.5rem;
				fill: var(--color-primary);
				display: none;
			}
		}
	}

	@include media-breakpoint-down("md") {
		pointer-events: none;
	}
}

:global(.error) {
	.select:not(:global(.opened)) .head {
		border-color: var(--color-red) !important;
	}
}

.selectGroup {
	[data-label] {
		z-index: 1;
	}
}

.nativeSelect {
	opacity: 0;
	pointer-events: none;
	z-index: 0;

	@include media-breakpoint-down("md") {
		pointer-events: all;
	}
}
