.image {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center;

	picture {
		display: block;
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		height: 100%;
	}

	img {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
	}
}
