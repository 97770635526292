@import "../../../styles/fragments/grid.mixins";

.promptWrapper {
	width: 100%;

	&.type-input {
		position: relative;

		:global(.field) {
			padding-right: 3rem;
		}

		[data-label] {
			padding-right: 3rem;
		}

		.promptGroup {
			top: 0;
			right: 0;
			height: 2.4rem;
			position: absolute;

			.prompt {
				position: absolute;
				top: calc(50% - 2.5rem / 2);
				right: 0;
			}
		}
	}

	&.type-checkbox {
		display: flex;
		flex-direction: row;
		align-items: center;

		.promptGroup {
			margin-left: 1rem;
		}
	}

	.promptGroup {
		position: relative;
		z-index: 2;

		&.visible .textGroup {
			opacity: 1;
			pointer-events: all;
		}

		.prompt {
			cursor: pointer;

			// &:hover ~ .textGroup {
			// 	display: block;
			// }
		}

		.textGroup {
			width: var(--width);
			padding: 1.5rem;
			background: var(--color-background-secondary);
			border-radius: var(--border-radius-square);
			box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
			border: 1px solid rgba(216, 220, 227, 0.5) !important;
			position: absolute;
			left: calc(50% - var(--width) / 2);
			bottom: calc(100% + 1rem);
			opacity: 0;
			pointer-events: none;
			line-height: 2rem;
			z-index: 9;
			max-width: calc(100vw - 3rem);

			--width: 40rem;

			@include media-breakpoint-down("md") {
				--width: 25rem;
			}
		}
	}
}
