@import "../../styles/fragments/grid.mixins";

.section {
	.wrapper {
		box-shadow: none;
		background: var(--color-primary-red) !important;

		.content {
			.title {
				color: white;

				span {
					display: inline-block;
				}
			}

			.steps {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;
				margin-top: 6rem;

				.step {
					.icon {
						width: 4.8rem;
						height: 4.8rem;
						fill: white;
					}

					.description {
						font-weight: 600;
						font-size: 2rem;
						line-height: 2.4rem;
						color: white;
						margin-top: 1rem;

						@include media-breakpoint-down("md") {
							font-size: 1.6rem;
							line-height: 2rem;
						}
					}

					@include media-breakpoint-down("md") {
						padding: 1.5rem;
						max-width: 50%;
						flex: 0 0 50%;

						// &:not(:nth-child(1)) {
						// 	margin-top: 2.4rem;
						// }
					}
				}

				@include media-breakpoint-down("md") {
					flex-direction: row;
					flex-wrap: wrap;
					margin: 4rem -1.5rem 0;
				}
			}
		}
	}
}

.reasons {
	padding: 6rem 0 4rem;
}
