@import "../../../styles/fragments/grid.mixins";

.containerWrapper {
	margin: 0 auto;
	width: 100%;
	padding: 0 var(--container-wrapper-padding);
	max-width: calc(1140px + var(--container-wrapper-padding) * 2);

	--container-wrapper-padding: 5rem;

	@include media-breakpoint-down("md") {
		max-width: 100vw;

		--container-wrapper-padding: 0;
	}
}
