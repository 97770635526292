.checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;

	&.labelPosition-left {
		flex-direction: row-reverse;

		.label {
			margin-left: 0;
			margin-right: 1rem;
		}
	}

	input:checked ~ {
		.button {
			background: var(--color-primary-red);
			border-color: var(--color-primary-red);

			.iconGroup {
				opacity: 1;
			}
		}

		.label {
			color: var(--color-primary-red);
		}
	}

	.button {
		width: 2.5rem;
		height: 2.5rem;
		position: relative;
		border-radius: 0.7rem;
		background: transparent;
		border: 1px solid #7c8da6;
		flex: 0 0 2.5rem;

		.iconGroup {
			display: flex;
			fill: white;
			position: absolute;
			width: 75%;
			height: 75%;
			margin: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
		}
	}

	.label {
		color: #7c8da6;
		font-weight: 600;
		font-size: 1.8rem;
		line-height: 2.4rem;
		margin-left: 1rem;
		cursor: default;
	}
}
