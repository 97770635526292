.spinner {
	width: 100%;
	height: 100%;
	position: relative;

	svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 5rem;
		height: 5rem;
		animation: rotate 2s linear infinite;

		circle {
			stroke-width: 3;
			stroke: var(--color-primary);
			stroke-linecap: round;
			animation: dash 1.5s ease-in-out infinite;
		}
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}
