@import "../../../styles/fragments/grid.mixins";

.bannerLayout {
	position: relative;

	.background,
	.foreground {
		position: absolute;
		padding: inherit;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}

	.foreground {
		z-index: 1;

		.title {
			font-weight: 800;
			line-height: 110%;
			font-size: 3.6rem;
			font-family: var(--font-primary);

			@include media-breakpoint-down("md") {
				font-size: 2.8rem;
				text-align: center;
			}
		}

		.description {
			font-size: 2rem;
			margin-top: 1.5rem;
			line-height: 120%;

			@include media-breakpoint-down("md") {
				font-size: 1.6rem;
				text-align: center;
			}
		}
	}
}
